/* eslint-disable */
// import { $ } from "jquery";
import IdPSelectUIParms from "./idpselect_config";
import disco from "./disco";

function promisify(callbackBasedApi) {
  return function promisified() {
    const args = [].slice.call(arguments);
    return new Promise(function (resolve, reject) {
      args.push(function (err, result) {
        if (err) {
          return reject(err);
        }
        if (arguments.length <= 2) {
          resolve(result);
        } else {
          resolve([].slice.call(arguments, 1));
        }
      });
      callbackBasedApi.apply(null, args);
    });
  };
}

function IdPSelectLanguages() {
  this.langBundles = {
    en: {
      "fatal.divMissing":
        '<div> specified  as "insertAtDiv" could not be located in the HTML',
      "fatal.noXMLHttpRequest":
        "Browser does not support XMLHttpRequest, unable to load IdP selection data",
      "fatal.wrongProtocol":
        'Policy supplied to DS was not "urn:oasis:names:tc:SAML:profiles:SSO:idpdiscovery-protocol:single"',
      "fatal.wrongEntityId":
        "entityId supplied by SP did not match configuration",
      "fatal.noData": "Metadata download returned no data",
      "fatal.loadFailed": "Failed to download metadata from ",
      "fatal.noparms":
        "No parameters to discovery session and no defaultReturn parameter configured",
      "fatal.noReturnURL": "No URL return parameter provided",
      "fatal.badProtocol":
        "Return parameter must start with https:// or http://",
      "fatal.badReturnString": "Return parameter is not whitelisted",
      "idpPreferred.label": "Use a suggested selection:",
      "idpEntry.label": "Or enter your organization's name",
      "idpEntry.NoPreferred.label": "Enter your organization's name",
      "idpList.label": "Or select your organization from the list below",
      "idpList.NoPreferred.label":
        "Select your organization from the list below",
      "idpList.defaultOptionLabel": "Please select your organization...",
      "idpList.showList": "Allow me to pick from a list",
      "idpList.showSearch": "Allow me to specify the site",
      "submitButton.label": "Continue",
      helpText: "Help",
      defaultLogoAlt: "DefaultLogo",
      "autoFollow.message": "Always follows this selection",
      "autoFollow.never": "Never",
      "autoFollow.time0": "One day",
      "autoFollow.time1": "3 months",
      "autoFollow.time2": "9 months",
    },
    de: {
      "fatal.divMissing": "Das notwendige Div Element fehlt",
      "fatal.noXMLHttpRequest":
        "Ihr Webbrowser unterst\u00fctzt keine XMLHttpRequests, IdP-Auswahl kann nicht geladen werden",
      "fatal.wrongProtocol":
        'DS bekam eine andere Policy als "urn:oasis:names:tc:SAML:profiles:SSO:idpdiscovery-protocol:single"',
      "fatal.wrongEntityId": "Die entityId ist nicht korrekt",
      "fatal.noData": "Heruntergeladene Metadata waren leer",
      "fatal.loadFailed": "Metadaten konnten nicht heruntergeladen werden: ",
      "fatal.noparms":
        "Parameter f\u00fcr das Discovery Service oder 'defaultReturn' fehlen",
      "fatal.noReturnURL": "URL return Parmeter fehlt",
      "fatal.badProtocol":
        "return Request muss mit https:// oder http:// beginnen",
      "fatal.badReturnString":
        "Return Parameter ist nicht auf Positivliste enthalten",
      "idpPreferred.label": "Vorherige Auswahl:",
      "idpEntry.label": "Oder geben Sie den Namen (oder Teile davon) an:",
      "idpEntry.NoPreferred.label":
        "Namen (oder Teile davon) der Institution angeben:",
      "idpList.label": "Oder w\u00e4hlen Sie Ihre Institution aus einer Liste:",
      "idpList.NoPreferred.label":
        "Institution aus folgender Liste w\u00e4hlen:",
      "idpList.defaultOptionLabel": "W\u00e4hlen Sie Ihre Institution aus...",
      "idpList.showList": "Institution aus einer Liste w\u00e4hlen",
      "idpList.showSearch": "Institution selbst angeben",
      "submitButton.label": "OK",
      helpText: "Hilfe",
      defaultLogoAlt: "Standard logo",
      "autoFollow.message":
        "Auswahl merken und diesen Dialog nicht mehr anzeigen",
      "autoFollow.never": "Nicht merken",
      "autoFollow.time0": "Nur f\u00fcr Heute",
      "autoFollow.time1": "Drei Monate lang",
      "autoFollow.time2": "Neun Monate lang",
    },
    ja: {
      "fatal.divMissing":
        '"insertAtDiv" の ID を持つ <div> が HTML 中に存在しません',
      "fatal.noXMLHttpRequest":
        "ブラウザが XMLHttpRequest をサポートしていないので IdP 情報を取得できません",
      "fatal.wrongProtocol":
        'DSへ渡された Policy パラメータが "urn:oasis:names:tc:SAML:profiles:SSO:idpdiscovery-protocol:single" ではありません',
      "fatal.wrongEntityId": "SP から渡された entityId が設定値と異なります",
      "fatal.noData": "メタデータが空です",
      "fatal.loadFailed":
        "次の URL からメタデータをダウンロードできませんでした: ",
      "fatal.noparms":
        "DSにパラメータが渡されておらず defaultReturn も設定されていません",
      "fatal.noReturnURL": "戻り URL が指定されていません",
      "fatal.badProtocol":
        "戻り URL は https:// か http:// で始まらなければなりません",
      "idpPreferred.label": "選択候補の IdP:",
      "idpEntry.label": "もしくはあなたの所属機関名を入力してください",
      "idpEntry.NoPreferred.label": "あなたの所属機関名を入力してください",
      "idpList.label": "もしくはあなたの所属機関を選択してください",
      "idpList.NoPreferred.label": "あなたの所属機関を一覧から選択してください",
      "idpList.defaultOptionLabel": "所属機関を選択してください...",
      "idpList.showList": "一覧から選択する",
      "idpList.showSearch": "機関名を入力する",
      "submitButton.label": "選択",
      "autoFollow.message": "次の期間選択した機関に自動的に遷移する:",
      "autoFollow.never": "自動遷移しない",
      "autoFollow.time0": "1日",
      "autoFollow.time1": "3か月",
      "autoFollow.time2": "9か月",
      "fatal.badReturnString": "戻り URL が不正です",
      helpText: "ヘルプ",
      defaultLogoAlt: "ロゴ未設定",
    },
    "pt-br": {
      "fatal.divMissing":
        'A tag <div> com "insertAtDiv" não foi encontrada no arquivo HTML',
      "fatal.noXMLHttpRequest":
        'Seu navegador não suporta "XMLHttpRequest", impossível de carregador os dados do IdP selecionado',
      "fatal.wrongProtocol":
        'A política "Policy" fornecida para o DS não foi "urn:oasis:names:tc:SAML:profiles:SSO:idpdiscovery-protocol:single"',
      "fatal.wrongEntityId":
        "entityId oferecido pelo SP não confere com o da configuração",
      "fatal.noData": "O arquivo de metadados não retornou nada;",
      "fatal.loadFailed": "Falhou ao realizar download do metadado de ",
      "fatal.noparms":
        'Sem parâmetros para sessão de descoberta e sem parâmetro "defaultReturn" configurado',
      "fatal.noReturnURL":
        "Não foi definida um endereço (URL) de retorno no parâmetro",
      "fatal.badProtocol":
        "Retorno do endereço requisitado deve começar com https:// ou http://",
      "idpPreferred.label": "Use estas Instituições sugeridas: ",
      "idpEntry.label": "Ou informe o nome da sua Instituição",
      "idpEntry.NoPreferred.label": "Informe o nome da sua Instituição",
      "idpList.label": "Ou selecione sua Instituição através da lista abaixo",
      "idpList.NoPreferred.label":
        "Selecione sua Instituição através da lista abaixo",
      "idpList.defaultOptionLabel": "Por favor, selecione sua Instituição: ",
      "idpList.showList": "Permitir que eu escolha um IdP através de uma lista",
      "idpList.showSearch": "Permitir que eu especifique o IdP",
      "submitButton.label": "Continuar ",
      helpText: "Ajuda",
      defaultLogoAlt: "Logo padrão",
    },
  };
}
function TypeAheadControl(l, f, j, g, i, b, h, e, a, c, d, k) {
  this.elementList = l;
  this.textBox = f;
  this.origin = j;
  this.submit = g;
  this.results = 0;
  this.alwaysShow = c;
  this.maxResults = d;
  this.ie6hack = a;
  this.maxchars = i;
  this.getName = b;
  this.getEntityId = h;
  this.geticon = e;
  this.getKeywords = k;
}
TypeAheadControl.prototype.draw = function (b) {
  var a = this;
  this.dropDown = document.createElement("ul");
  this.dropDown.className = "IdPSelectDropDown";
  this.dropDown.style.visibility = "hidden";
  this.dropDown.style.width = this.textBox.offsetWidth;
  this.dropDown.current = -1;
  this.textBox.setAttribute("role", "listbox");
  document.body.appendChild(this.dropDown);
  this.textBox.setAttribute("role", "combobox");
  this.textBox.setAttribute("aria-controls", "IdPSelectDropDown");
  this.textBox.setAttribute("aria-owns", "IdPSelectDropDown");
  this.dropDown.onmouseover = function (c) {
    if (!c) {
      c = window.event;
    }
    var d;
    if (c.target) {
      d = c.target;
    }
    if (typeof d == "undefined") {
      d = c.srcElement;
    }
    a.select(d);
  };
  this.dropDown.onmousedown = function (c) {
    if (-1 != a.dropDown.current) {
      a.textBox.value = a.results[a.dropDown.current][0];
    }
  };
  this.textBox.onkeyup = function (c) {
    if (!c) {
      c = window.event;
    }
    a.handleKeyUp(c);
  };
  this.textBox.onkeydown = function (c) {
    if (!c) {
      c = window.event;
    }
    a.handleKeyDown(c);
  };
  this.textBox.onblur = function () {
    a.hideDrop();
  };
  this.textBox.onfocus = function () {
    a.handleChange();
  };
  if (null == b || b) {
    this.textBox.focus();
  }
};
TypeAheadControl.prototype.getPossible = function (b) {
  var h = [];
  var j = 0;
  var f = 0;
  var e = 0;
  var g;
  var i;
  b = b.toLowerCase();
  while (f <= this.maxResults && j < this.elementList.length) {
    var a = false;
    var c = this.getName(this.elementList[j]);
    if (c.toLowerCase().indexOf(b) != -1) {
      a = true;
    }
    if (
      !a &&
      this.getEntityId(this.elementList[j]).toLowerCase().indexOf(b) != -1
    ) {
      a = true;
    }
    if (!a) {
      var d = this.getKeywords(this.elementList[j]);
      if (null != d && d.toLowerCase().indexOf(b) != -1) {
        a = true;
      }
    }
    if (a) {
      h[f] = [
        c,
        this.getEntityId(this.elementList[j]),
        this.geticon(this.elementList[j]),
      ];
      f++;
    }
    j++;
  }
  this.dropDown.current = -1;
  return h;
};
TypeAheadControl.prototype.handleKeyUp = function (b) {
  var a = b.keyCode;
  if (27 == a) {
    this.textBox.value = "";
    this.handleChange();
  } else {
    if (8 == a || 32 == a || (a >= 46 && a < 112) || a > 123) {
      this.handleChange();
    }
  }
};
TypeAheadControl.prototype.handleKeyDown = function (b) {
  var a = b.keyCode;
  if (38 == a) {
    this.upSelect();
  } else {
    if (40 == a) {
      this.downSelect();
    }
  }
};
TypeAheadControl.prototype.hideDrop = function () {
  var a = 0;
  if (null !== this.ie6hack) {
    while (a < this.ie6hack.length) {
      this.ie6hack[a].style.visibility = "visible";
      a++;
    }
  }
  this.dropDown.style.visibility = "hidden";
  this.textBox.setAttribute("aria-expanded", "false");
  if (-1 == this.dropDown.current) {
    this.doUnselected();
  }
};
TypeAheadControl.prototype.showDrop = function () {
  var a = 0;
  if (null !== this.ie6hack) {
    while (a < this.ie6hack.length) {
      this.ie6hack[a].style.visibility = "hidden";
      a++;
    }
  }
  this.dropDown.style.visibility = "visible";
  this.dropDown.style.width = this.textBox.offsetWidth + "px";
  this.textBox.setAttribute("aria-expanded", "true");
};
TypeAheadControl.prototype.doSelected = function () {
  this.submit.disabled = false;
};
TypeAheadControl.prototype.doUnselected = function () {
  this.submit.disabled = true;
  this.textBox.setAttribute("aria-activedescendant", "");
};
TypeAheadControl.prototype.handleChange = function () {
  var b = this.textBox.value;
  var a = this.getPossible(b);
  if (
    0 === b.length ||
    0 === a.length ||
    (!this.alwaysShow && this.maxResults < a.length)
  ) {
    this.hideDrop();
    this.doUnselected();
    this.results = [];
    this.dropDown.current = -1;
  } else {
    this.results = a;
    this.populateDropDown(a);
    if (1 == a.length) {
      this.select(this.dropDown.childNodes[0]);
      this.doSelected();
    } else {
      this.doUnselected();
    }
  }
};
TypeAheadControl.prototype.populateDropDown = function (d) {
  this.dropDown.innerHTML = "";
  var c = 0;
  var a;
  var b;
  var f;
  while (c < d.length) {
    a = document.createElement("li");
    a.id = "IdPSelectOption" + c;
    f = d[c][0];
    if (null !== d[c][2]) {
      b = document.createElement("img");
      b.src = d[c][2];
      b.width = 16;
      b.height = 16;
      b.alt = "";
      a.appendChild(b);
      if (f.length > this.maxchars - 2) {
        f = f.substring(0, this.maxchars - 2);
      }
      f = " " + f;
    } else {
      if (f.length > this.maxchars) {
        f = f.substring(0, this.maxchars);
      }
    }
    a.appendChild(document.createTextNode(f));
    a.setAttribute("role", "option");
    this.dropDown.appendChild(a);
    c++;
  }
  var e = this.getXY();
  this.dropDown.style.left = e[0] + "px";
  this.dropDown.style.top = e[1] + "px";
  this.showDrop();
};
TypeAheadControl.prototype.getXY = function () {
  var el = this.textBox;
  var x = $(el).offset().left;
  var y = $(el).offset().top + $(el).outerHeight();
  // while (a !== null && a.tagName != "BODY") {
  //   c += a.offsetLeft;
  //   b += a.offsetTop;
  //   a = a.offsetParent;
  // }
  // c += a.offsetLeft;
  // b += a.offsetTop;
  return [x, y];
};
TypeAheadControl.prototype.select = function (b) {
  var a = 0;
  var c;
  this.dropDown.current = -1;
  this.doUnselected();
  while (a < this.dropDown.childNodes.length) {
    c = this.dropDown.childNodes[a];
    if (c == b) {
      c.className = "IdPSelectCurrent";
      c.setAttribute("aria-selected", "true");
      this.textBox.setAttribute("aria-activedescendant", "IdPSelectOption" + a);
      this.doSelected();
      this.dropDown.current = a;
      this.origin.value = this.results[a][1];
      this.origin.textValue = this.results[a][0];
    } else {
      c.setAttribute("aria-selected", "false");
      c.className = "";
    }
    a++;
  }
  this.textBox.focus();
};
TypeAheadControl.prototype.downSelect = function () {
  if (this.results.length > 0) {
    if (-1 == this.dropDown.current) {
      this.dropDown.current = 0;
      this.dropDown.childNodes[0].className = "IdPSelectCurrent";
      this.dropDown.childNodes[0].setAttribute("aria-selected", "true");
      this.textBox.setAttribute("aria-activedescendant", "IdPSelectOption" + 0);
      this.doSelected();
      this.origin.value = this.results[0][1];
      this.origin.textValue = this.results[0][0];
    } else {
      if (this.dropDown.current < this.results.length - 1) {
        this.dropDown.childNodes[this.dropDown.current].className = "";
        this.dropDown.current++;
        this.dropDown.childNodes[this.dropDown.current].className =
          "IdPSelectCurrent";
        this.dropDown.childNodes[this.dropDown.current].setAttribute(
          "aria-selected",
          "true"
        );
        this.textBox.setAttribute(
          "aria-activedescendant",
          "IdPSelectOption" + this.dropDown.current
        );
        this.doSelected();
        this.origin.value = this.results[this.dropDown.current][1];
        this.origin.textValue = this.results[this.dropDown.current][0];
      }
    }
  }
};
TypeAheadControl.prototype.upSelect = function () {
  if (this.results.length > 0 && this.dropDown.current > 0) {
    this.dropDown.childNodes[this.dropDown.current].className = "";
    this.dropDown.current--;
    this.dropDown.childNodes[this.dropDown.current].className =
      "IdPSelectCurrent";
    this.dropDown.childNodes[this.dropDown.current].setAttribute(
      "aria-selected",
      "true"
    );
    this.textBox.setAttribute(
      "aria-activedescendant",
      "IdPSelectOption" + this.dropDown.current
    );
    this.doSelected();
    this.origin.value = this.results[this.dropDown.current][1];
    this.origin.textValue = this.results[this.dropDown.current][0];
  }
};
function IdPSelectUI() {
  var res;
  var W = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
  var rootDivEl;
  var S;
  var aB;
  var an;
  var aa;
  var d;
  var F;
  var n;
  var y;
  var k;
  var aw;
  var g;
  var t;
  var ab;
  var ah;
  var preferredIdP;
  var maxPreferredIdPs;
  var helpURL;
  var ie6Hack;
  var samlIdPCookieTTL;
  var au;
  var N;
  var T;
  var alwaysShow;
  var maxResults;
  var ignoreKeywords;
  var c;
  var af;
  var ignoreURLParams;
  var Q;
  var Z;
  var P;
  var U;
  var j;
  var az;
  var V;
  var aL = "idpSelect";
  var aj = "IdPSelect";
  var ar;
  var defaultReturn = "";
  var defaultLogin = "";
  var Y = "";
  var aA = [];
  var aG = "entityID";
  var loginDiv = $("#login .loading-overlay");
  this.draw = function (idpsUIparams) {
    rootDivEl = document.getElementById(idpsUIparams.insertAtDiv);
    if (!m(idpsUIparams)) {
      loginDiv.length > 0 && loginDiv.fadeOut(250);
      return;
    }
    if (!rootDivEl) {
      // noDisco(A("fatal.divMissing"));
      loginDiv.length > 0 && loginDiv.fadeOut(250);
      return;
    }
    if (null != Q && null != i(Q)) {
      var aM = b();
      if (aM.length != 0) {
        var aO = aG + "=" + encodeURIComponent(aM[0]);
        if (defaultReturn.indexOf("?") == -1) {
          aO = "?" + aO;
        } else {
          aO = "&" + aO;
        }
        createAndClickLink(rootDivEl, defaultReturn + aO);
        loginDiv.length > 0 && loginDiv.fadeOut(250);
        return;
      }
    }
    fetchDiscoFeed(idpsUIparams.dataSource)
      .then(function () {
        aK();
        u(idpsUIparams.hiddenIdPs);
        res.sort(function (aR, aQ) {
          return aE(aR).localeCompare(aE(aQ));
        });
        var aN = ac();
        rootDivEl.appendChild(aN);
        ar.draw(idpsUIparams.setFocusTextBox);

        // Top label
        $("#idpSelectPreferredIdPTile").before(
          $("#idpSelectPreferredIdPTile > div.IdPSelectTextDiv")
        );

        // Input form
        $("#idpSelectInput").addClass("form-control");
        $("#idpSelectInput").wrap('<div class="input-group mb-4">');
        $("#idpSelectSelectButton").wrap('<div class="input-group-append">');
        $("#idpSelectSelectButton").addClass("btn btn-outline-secondary");
        $("#idpSelectInput").after(
          $("#idpSelectIdPEntryTile > form > div.input-group-append")
        );
        // $("#idpSelectIdPEntryTile > a.IdPSelectDropDownToggle, #idpSelectIdPEntryTile > a.IdPSelectHelpButton, #idpSelectIdPListTile > a.IdPSelectDropDownToggle, #idpSelectIdPListTile > a.IdPSelectHelpButton").wrap("<p>");

        // Dropdown
        $("#idpSelectSelector").addClass("form-control dropdown");
        $("#idpSelectSelector").wrap('<div class="input-group mb-4">');
        $("#idpSelectListButton").wrap('<div class="input-group-append">');
        $("#idpSelectListButton").addClass("btn btn-outline-secondary");
        $("#idpSelectSelector").after(
          $("#idpSelectIdPListTile > form > div.input-group-append")
        );
        $("#idpSelectSelectButton, #idpSelectListButton").addClass(
          "button primary"
        );
        loginDiv.length > 0 && loginDiv.fadeOut(250);
      })
      .catch(function (err) {
        console.log(err);
        loginDiv.length > 0 && loginDiv.fadeOut(250);
        noDisco(A("fatal.loadFailed"));
      });
  };
  var m = function (idpsUIparams) {
    var aN;
    preferredIdP = idpsUIparams.preferredIdP;
    maxPreferredIdPs = idpsUIparams.maxPreferredIdPs;
    helpURL = idpsUIparams.helpURL;
    ie6Hack = idpsUIparams.ie6Hack;
    samlIdPCookieTTL = idpsUIparams.samlIdPCookieTTL;
    alwaysShow = idpsUIparams.alwaysShow;
    maxResults = idpsUIparams.maxResults;
    ignoreKeywords = idpsUIparams.ignoreKeywords;
    if (idpsUIparams.showListFirst) {
      c = idpsUIparams.showListFirst;
    } else {
      c = false;
    }
    if (idpsUIparams.noWriteCookie) {
      af = idpsUIparams.noWriteCookie;
    } else {
      af = false;
    }
    if (idpsUIparams.ignoreURLParams) {
      ignoreURLParams = idpsUIparams.ignoreURLParams;
    } else {
      ignoreURLParams = false;
    }
    defaultLogin = idpsUIparams.defaultLogin;
    F = idpsUIparams.defaultLogo;
    n = idpsUIparams.defaultLogoWidth;
    y = idpsUIparams.defaultLogoHeight;
    k = idpsUIparams.minWidth;
    aw = idpsUIparams.minHeight;
    g = idpsUIparams.maxWidth;
    t = idpsUIparams.maxHeight;
    ab = idpsUIparams.bestRatio;
    if (null == idpsUIparams.doNotCollapse) {
      ah = true;
    } else {
      ah = idpsUIparams.doNotCollapse;
    }
    N = idpsUIparams.maxIdPCharsButton;
    au = idpsUIparams.maxIdPCharsDropDown;
    T = idpsUIparams.maxIdPCharsAltTxt;
    Q = idpsUIparams.autoFollowCookie;
    Z = idpsUIparams.autoFollowCookieTTLs;
    var a3;
    if (typeof navigator == "undefined") {
      a3 = idpsUIparams.defaultLanguage;
    } else {
      a3 =
        navigator.language ||
        navigator.userLanguage ||
        idpsUIparams.defaultLanguage;
    }
    a3 = a3.toLowerCase();
    if (a3.indexOf("-") > 0) {
      aB = a3.substring(0, a3.indexOf("-"));
    }
    var aX = new IdPSelectLanguages();
    an = idpsUIparams.defaultLanguage;
    if (
      typeof idpsUIparams.langBundles != "undefined" &&
      typeof idpsUIparams.langBundles[a3] != "undefined"
    ) {
      aa = idpsUIparams.langBundles[a3];
    } else {
      if (typeof aX.langBundles[a3] != "undefined") {
        aa = aX.langBundles[a3];
      } else {
        if (typeof aB != "undefined") {
          if (
            typeof idpsUIparams.langBundles != "undefined" &&
            typeof idpsUIparams.langBundles[aB] != "undefined"
          ) {
            aa = idpsUIparams.langBundles[aB];
          } else {
            if (typeof aX.langBundles[aB] != "undefined") {
              aa = aX.langBundles[aB];
            }
          }
        }
      }
    }
    if (
      typeof idpsUIparams.langBundles != "undefined" &&
      typeof idpsUIparams.langBundles[idpsUIparams.defaultLanguage] !=
        "undefined"
    ) {
      d = idpsUIparams.langBundles[idpsUIparams.defaultLanguage];
    } else {
      d = aX.langBundles[idpsUIparams.defaultLanguage];
    }
    if (!d) {
      noDisco("No languages work");
      return false;
    }
    if (!aa) {
      s("No language support for " + a3);
      aa = d;
    }
    if (idpsUIparams.testGUI) {
      return true;
    }
    var aY =
      "urn:oasis:names:tc:SAML:profiles:SSO:idpdiscovery-protocol:single";
    var a1;
    var a2 = false;
    var aR;
    var aU;
    var aQ = window;
    while (null !== aQ.parent && aQ !== aQ.parent) {
      aQ = aQ.parent;
    }
    var aW = aQ.location;
    var aS = aW.search;
    if (
      ignoreURLParams ||
      null == aS ||
      0 == aS.length ||
      aS.charAt(0) != "?"
    ) {
      if (null == idpsUIparams.defaultReturn && !ignoreURLParams) {
        noDisco(A("fatal.noparms"));
        // return false;
      }
      aN = idpsUIparams.myEntityID;
      defaultReturn = idpsUIparams.defaultReturn;
      if (null != idpsUIparams.defaultReturnIDParam) {
        aG = idpsUIparams.defaultReturnIDParam;
      }
    } else {
      aS = aS.substring(1);
      aR = aS.split("&");
      if (aR.length === 0) {
        noDisco(A("fatal.noparms"));
        return false;
      }
      for (a1 = 0; a1 < aR.length; a1++) {
        aU = aR[a1].split("=");
        if (aU.length != 2) {
          continue;
        }
        if (aU[0] == "entityID") {
          aN = decodeURIComponent(aU[1]);
        } else {
          if (aU[0] == "return") {
            defaultReturn = decodeURIComponent(aU[1]);
          } else {
            if (aU[0] == "returnIDParam") {
              aG = decodeURIComponent(aU[1]);
            } else {
              if (aU[0] == "policy") {
                aY = decodeURIComponent(aU[1]);
              } else {
                if (aU[0] == "isPassive") {
                  a2 = aU[1].toUpperCase() == "TRUE";
                }
              }
            }
          }
        }
      }
    }
    var aM;
    if (null == idpsUIparams.allowableProtocols) {
      aM = [
        "urn:oasis:names:tc:SAML:profiles:SSO:idpdiscovery-protocol:single",
      ];
    } else {
      aM = idpsUIparams.allowableProtocols;
    }
    var a0 = false;
    for (var a1 = 0; a1 < aM.length; a1++) {
      var aZ = aM[a1];
      if (aY == aZ) {
        a0 = true;
        break;
      }
    }
    if (!a0) {
      noDisco(A("fatal.wrongProtocol"));
      return false;
    }
    if (idpsUIparams.myEntityID !== null && idpsUIparams.myEntityID != aN) {
      noDisco(
        A("fatal.wrongEntityId") +
          '"' +
          aN +
          '" != "' +
          idpsUIparams.myEntityID +
          '"'
      );
      return false;
    }
    if (null === defaultReturn || defaultReturn.length === 0) {
      noDisco(A("fatal.noReturnURL"));
      return false;
    }
    if (!ao(defaultReturn)) {
      noDisco(A("fatal.badProtocol"));
      return false;
    }
    if (!e(idpsUIparams.returnWhiteList, defaultReturn)) {
      ae(A("fatal.badReturnString"));
      return false;
    }
    if (a2) {
      var aP = b();
      var aV = document.getElementById(parmsSupplied.insertAtDiv);
      if (aP.length == 0) {
        createAndClickLink(aV, defaultReturn);
        return false;
      } else {
        var aO = aG + "=" + encodeURIComponent(aP[0]);
        if (defaultReturn.indexOf("?") == -1) {
          aO = "?" + aO;
        } else {
          aO = "&" + aO;
        }
        createAndClickLink(aV, defaultReturn + aO);
        return false;
      }
    }
    a1 = defaultReturn.indexOf("?");
    if (a1 < 0) {
      Y = defaultReturn;
      return true;
    }
    Y = defaultReturn.substring(0, a1);
    aS = defaultReturn.substring(a1 + 1);
    aR = aS.split("&");
    for (a1 = 0; a1 < aR.length; a1++) {
      aU = aR[a1].split("=");
      if (aU.length != 2) {
        continue;
      }
      aU[1] = decodeURIComponent(aU[1]);
      aA.push(aU);
    }
    return true;
  };
  var aK = function () {
    var aO = [];
    var aN;
    for (aN = 0; aN < res.length; ) {
      var aM = z(res[aN]);
      if (null == aO[aM]) {
        aO[aM] = aM;
        aN = aN + 1;
      } else {
        res.splice(aN, 1);
      }
    }
  };
  var u = function (aO) {
    if (null == aO || 0 == aO.length) {
      return;
    }
    var aN;
    var aM;
    for (aN = 0; aN < aO.length; aN++) {
      for (aM = 0; aM < res.length; aM++) {
        if (z(res[aM]) == aO[aN]) {
          res.splice(aM, 1);
          break;
        }
      }
    }
  };
  var ao = function (aN) {
    if (null === aN) {
      return false;
    }
    var aM = "://";
    var aO = aN.indexOf(aM);
    if (aO < 0) {
      return false;
    }
    aN = aN.substring(0, aO);
    if (aN == "http" || aN == "https") {
      return true;
    }
    return false;
  };
  var e = function (aM, aN) {
    if (null == aM) {
      return true;
    }
    for (var aO = 0; aO < aM.length; aO++) {
      var aP = new RegExp(aM[aO]);
      if (aP.test(aN)) {
        return true;
      }
    }
    return false;
  };
  var aI = function () {
    if (null == navigator) {
      return false;
    }
    var aM = navigator.appName;
    if (null == aM) {
      return false;
    }
    return aM == "Microsoft Internet Explorer";
  };
  var createAndClickLink = function (aN, href) {
    var linkEl = document.createElement("a");
    linkEl.href = href;
    aN.appendChild(linkEl);
    linkEl.click();
  };

  var fetchDiscoFeedAJAX = function () {
    var url = idpsUIparams.dataSource;
    if (!url) {
      return Promise.reject();
    }
    if (aI()) {
      url += "?random=" + Math.random() * 1000000;
    }
    function getDisco(url, cb) {
      $.get(url, function (data, status, resp) {
        if (resp.status == 200) {
          if (data === null) {
            cb(noDisco(A("fatal.noData")));
          }
          cb(null, data);
        } else {
          cb(noDisco(A("fatal.loadFailed") + url));
        }
      });
    }
    var fetch = promisify(getDisco);
    return fetch(url);
  };
  function test(val) {
    if (val.length <= 0) {
      throw val;
    } else {
      return val;
    }
  }
  var fetchDiscoFeed = function () {
    var p;
    if (disco && disco.length > 0) {
      p = Promise.resolve(disco);
    } else {
      // Backup plan: If the discofeed isn't loaded at the top for some reason,
      // then fetch it from the datasource. It's a BIG file, try a few times,
      // wait 1s in between
      p = Promise.reject();
      for (var i = 0; i < 3; i++) {
        (function (i) {
          p = p
            .catch(fetchDiscoFeedAJAX)
            .then(test)
            .catch(function (reason) {
              return new Promise(function (resolve, reject) {
                setTimeout(reject.bind(null, reason), 1000);
              });
            });
        })(i);
      }
    }
    p = p.then(function (discofeed) {
      res = discofeed;
    });
    return p;
  };

  var ad = function (aM) {
    for (var aN = 0; aN < res.length; aN++) {
      if (z(res[aN]) == aM) {
        return res[aN];
      }
    }
    return null;
  };
  var H = function (aT, aN) {
    var aS = function (aW) {
      var aU = null;
      var logo;
      if (null == aT.Logos) {
        return null;
      }
      for (logo in aT.Logos) {
        if (
          aT.Logos[logo].lang == aW &&
          aT.Logos[logo].width != null &&
          aT.Logos[logo].width >= k &&
          aT.Logos[logo].height != null &&
          aT.Logos[logo].height >= aw
        ) {
          if (aU === null) {
            aU = aT.Logos[logo];
          } else {
            me = Math.abs(
              ab - Math.log(aT.Logos[logo].width / aT.Logos[logo].height)
            );
            him = Math.abs(ab - Math.log(aU.width / aU.height));
            if (him > me) {
              aU = aT.Logos[logo];
            }
          }
        }
      }
      return aU;
    };
    var aP = null;
    var aO = document.createElement("img");
    am(aO, "IdPImg");
    aP = aS(S);
    if (null === aP && typeof aB != "undefined") {
      aP = aS(aB);
    }
    if (null === aP) {
      aP = aS(null);
    }
    if (null === aP) {
      aP = aS(an);
    }
    if (null === aP) {
      if (!aN) {
        return null;
      }
      aO.src = F;
      aO.width = n;
      aO.height = y;
      aO.alt = A("defaultLogoAlt");
      return aO;
    }
    aO.src = aP.value;
    var aQ = aE(aT);
    if (aQ.length > T) {
      aQ = aQ.substring(0, T) + "...";
    }
    aO.alt = aQ;
    var aM = aP.width;
    var aR = aP.height;
    if (aM > g) {
      aR = (g / aM) * aR;
      aM = g;
    }
    if (aR > t) {
      aM = (t / aR) * aM;
      aR = t;
    }
    aO.setAttribute("width", aM);
    aO.setAttribute("height", aR);
    return aO;
  };
  var ac = function () {
    var aN = ap("IdPSelector");
    var aM;
    aM = aC(aN);
    o(aN, aM);
    X(aN, aM);
    if (null != Q) {
      C(aN);
    }
    return aN;
  };
  var M = function (aO, aV, aN) {
    var aM = ap(undefined, "PreferredIdPButton");
    var aU = document.createElement("a");
    var aT = aG + "=" + encodeURIComponent(z(aO));
    // var defaultReturn = defaultReturn;
    var aR = H(aO, aN);
    if (defaultLogin.indexOf("?") == -1) {
      aT = "?" + aT;
    } else {
      aT = "&" + aT;
    }
    if (defaultReturn) {
      aT = aT + "&target=" + encodeURIComponent(defaultReturn);
    }
    aU.href = defaultLogin + aT;
    aU.onclick = function () {
      aH(z(aO));
    };
    if (null != aR) {
      var aW = ap(undefined, "PreferredIdPImg");
      aW.appendChild(aR);
      aU.appendChild(aW);
    }
    var aS = ap(undefined, "TextDiv");
    var aQ = aE(aO);
    if (aQ.length > N) {
      aQ = aQ.substring(0, N) + "...";
    }
    aM.title = aQ;
    aS.appendChild(document.createTextNode(aQ));
    aU.appendChild(aS);
    aM.appendChild(aU);
    return aM;
  };
  var aF = function (aM, aP) {
    var aO = ap(undefined, "TextDiv");
    var aN = document.createTextNode(A(aP));
    aO.appendChild(aN);
    aM.appendChild(aO);
  };
  var a = function (aM, aO) {
    if (null === aO || 0 === aO.length || "-" == aO.value) {
      return;
    }
    var aN = 0;
    while (aN < aM.options.length) {
      if (aM.options[aN].value == aO) {
        aM.options[aN].selected = true;
        break;
      }
      aN++;
    }
  };
  var aC = function (aR) {
    var aQ = L();
    if (0 === aQ.length) {
      return false;
    }
    var aM = ah;
    for (var aO = 0; aO < maxPreferredIdPs && aO < aQ.length; aO++) {
      if (aQ[aO] && H(aQ[aO], false)) {
        aM = true;
      }
    }
    var aP;
    if (aM) {
      aP = ap("PreferredIdPTile");
    } else {
      aP = ap("PreferredIdPTileNoImg");
    }
    aF(aP, "idpPreferred.label");
    for (var aO = 0; aO < maxPreferredIdPs && aO < aQ.length; aO++) {
      if (aQ[aO]) {
        var aN = M(aQ[aO], aO, aM);
        aP.appendChild(aN);
      }
    }
    aR.appendChild(aP);
    return true;
  };
  var ai = function () {
    var aN = document.createElement("form");
    U.appendChild(aN);
    aN.action = defaultLogin;
    aN.method = "POST";
    aN.setAttribute("autocomplete", "OFF");
    var i = 0;
    aA = aA.concat([
      ["target", defaultReturn],
      [
        "authenticity_token",
        "+vFRxwHPaSaOcy6uxywudMU28nWoedRl/mUs3UQckaQhzfcikxFwzPTM+lJvYllf4sc5ozCO88Ei1YTuEjVdVA==",
      ],
    ]);
    for (i = 0; i < aA.length; i++) {
      var aO = document.createElement("input");
      aO.setAttribute("type", "hidden");
      aO.name = aA[i][0];
      aO.value = aA[i][1];
      aN.appendChild(aO);
    }
    return aN;
  };
  var o = function (aT, aN) {
    U = ap("IdPEntryTile");
    if (c) {
      U.style.display = "none";
    }
    var aO = document.createElement("label");
    aO.setAttribute("for", aL + "Input");
    if (aN) {
      aF(aO, "idpEntry.label");
    } else {
      aF(aO, "idpEntry.NoPreferred.label");
    }
    var aR = ai();
    aR.appendChild(aO);
    var aQ = document.createElement("input");
    aR.appendChild(aQ);
    aQ.type = "text";
    l(aQ, "Input");
    var aS = document.createElement("input");
    aS.setAttribute("type", "hidden");
    aR.appendChild(aS);
    aS.name = aG;
    aS.value = "-";
    var aP = v("Select");
    aP.disabled = true;
    aR.appendChild(aP);
    aR.onsubmit = function () {
      if (null === aS.value || 0 === aS.value.length || "-" == aS.value) {
        return false;
      }
      aQ.value = aS.textValue;
      aH(aS.value);
      return true;
    };
    ar = new TypeAheadControl(
      res,
      aQ,
      aS,
      aP,
      au,
      aE,
      z,
      ak,
      ie6Hack,
      alwaysShow,
      maxResults,
      I
    );
    var aM = document.createElement("a");
    aM.appendChild(document.createTextNode(A("idpList.showList")));
    aM.href = "#";
    am(aM, "DropDownToggle");
    aM.onclick = function () {
      U.style.display = "none";
      a(az, aS.value);
      j.style.display = "";
      V.focus();
      return false;
    };
    U.appendChild(aM);
    x(U);
    aT.appendChild(U);
  };
  var X = function (aM, aP) {
    j = ap("IdPListTile");
    if (!c) {
      j.style.display = "none";
    }
    var aT = document.createElement("label");
    aT.setAttribute("for", aL + "Selector");
    if (aP) {
      aF(aT, "idpList.label");
    } else {
      aF(aT, "idpList.NoPreferred.label");
    }
    az = document.createElement("select");
    l(az, "Selector");
    az.name = aG;
    j.appendChild(az);
    var aU = p("-", A("idpList.defaultOptionLabel"));
    aU.selected = true;
    az.appendChild(aU);
    var aO;
    for (var aQ = 0; aQ < res.length; aQ++) {
      aO = res[aQ];
      aU = p(z(aO), aE(aO));
      az.appendChild(aU);
    }
    var aN = ai();
    aN.appendChild(aT);
    aN.appendChild(az);
    aN.onsubmit = function () {
      if (az.selectedIndex < 1) {
        return false;
      }
      aH(az.options[az.selectedIndex].value);
      return true;
    };
    var aR = v("List");
    V = aR;
    aN.appendChild(aR);
    j.appendChild(aN);
    var aS = document.createElement("a");
    aS.appendChild(document.createTextNode(A("idpList.showSearch")));
    aS.href = "#";
    am(aS, "DropDownToggle");
    aS.onclick = function () {
      U.style.display = "";
      j.style.display = "none";
      return false;
    };
    j.appendChild(aS);
    x(j);
    aM.appendChild(j);
  };
  var C = function (aP) {
    var aN = "IdPSelectAutoDisp";
    autoDispatchTile = ap(undefined, "autoDispatchArea");
    autoDispatchTile.appendChild(
      document.createTextNode(A("autoFollow.message"))
    );
    var aM = document.createElement("input");
    aM.setAttribute("type", "radio");
    aM.setAttribute("checked", "checked");
    aM.setAttribute("name", aN);
    aM.onclick = function () {
      E(0);
    };
    div = ap(undefined, "autoDispatchTile");
    div.appendChild(aM);
    div.appendChild(document.createTextNode(A("autoFollow.never")));
    autoDispatchTile.appendChild(div);
    var aO;
    for (aO = 0; aO < Z.length; aO++) {
      aM = document.createElement("input");
      aM.setAttribute("type", "radio");
      aM.setAttribute("name", aN);
      aM.life = Z[aO];
      aM.onclick = function () {
        var aQ = this.life;
        E(aQ);
      };
      div = ap(undefined, "autoDispatchTile");
      div.appendChild(aM);
      div.appendChild(document.createTextNode(A("autoFollow.time" + aO)));
      autoDispatchTile.appendChild(div);
    }
    aP.appendChild(autoDispatchTile);
  };
  var v = function (aN) {
    var aM = document.createElement("input");
    aM.setAttribute("type", "submit");
    aM.value = A("submitButton.label");
    l(aM, aN + "Button");
    return aM;
  };
  var x = function (aN) {
    var aM = document.createElement("a");
    aM.href = helpURL;
    aM.appendChild(document.createTextNode(A("helpText")));
    am(aM, "HelpButton");
    aN.appendChild(aM);
  };
  var ap = function (aO, aM) {
    var aN = document.createElement("div");
    if (undefined !== aO) {
      l(aN, aO);
    }
    if (undefined !== aM) {
      am(aN, aM);
    }
    return aN;
  };
  var p = function (aN, aO) {
    var aM = document.createElement("option");
    aM.value = aN;
    if (aO.length > au) {
      aO = aO.substring(0, au);
    }
    aM.appendChild(document.createTextNode(aO));
    return aM;
  };
  var l = function (aN, aM) {
    aN.id = aL + aM;
  };
  var am = function (aN, aM) {
    aN.setAttribute("class", aj + aM);
  };
  var aD = function (aM) {
    return document.getElementById(aL + aM);
  };
  var aH = function (aM) {
    J(aM);
    at(P);
  };
  var A = function (aM) {
    var aN = aa[aM];
    if (!aN) {
      aN = d[aM];
    }
    if (!aN) {
      aN = "Missing message for " + aM;
    }
    return aN;
  };
  var z = function (aM) {
    return aM.entityID;
  };
  var ak = function (aO) {
    var aM;
    if (null == aO.Logos) {
      return null;
    }
    for (aM = 0; aM < aO.Logos.length; aM++) {
      var aN = aO.Logos[aM];
      if (aN.height == "16" && aN.width == "16") {
        if (
          null == aN.lang ||
          S == aN.lang ||
          (typeof aB != "undefined" && aB == aN.lang) ||
          an == aN.lang
        ) {
          return aN.value;
        }
      }
    }
    return null;
  };
  var aE = function (aN) {
    var aM = ay(aN.DisplayNames);
    if (null !== aM) {
      return aM;
    }
    s("No Name entry in any language for " + z(aN));
    return z(aN);
  };
  var I = function (aN) {
    if (ignoreKeywords || null == aN.Keywords) {
      return null;
    }
    var aM = ay(aN.Keywords);
    return aM;
  };
  var ay = function (aM) {
    var aN;
    for (aN in aM) {
      if (aM[aN].lang == S) {
        return aM[aN].value;
      }
    }
    if (typeof aB != "undefined") {
      for (aN in aM) {
        if (aM[aN].lang == aB) {
          return aM[aN].value;
        }
      }
    }
    for (aN in aM) {
      if (aM[aN].lang == null) {
        return aM[aN].value;
      }
    }
    for (aN in aM) {
      if (aM[aN].lang == an) {
        return aM[aN].value;
      }
    }
    return null;
  };
  var L = function () {
    var aQ = [];
    var aP = 0;
    var aO;
    var aN;
    if (null != preferredIdP) {
      for (aO = 0; aO < preferredIdP.length && aO < maxPreferredIdPs; aO++) {
        aQ[aO] = ad(preferredIdP[aO]);
        aP++;
      }
    }
    P = b();
    for (aO = aP, aN = 0; aN < P.length && aO < maxPreferredIdPs; aN++) {
      var aM = ad(P[aN]);
      if (typeof aQ.indexOf === "undefined") {
        aQ.push(aM);
        aO++;
      } else {
        if (aQ.indexOf(aM) === -1) {
          aQ.push(aM);
          aO++;
        }
      }
    }
    return aQ;
  };
  var J = function (aM) {
    var aN = [];
    while (0 !== P.length) {
      var aO = P.pop();
      if (aO != aM) {
        aN.unshift(aO);
      }
    }
    aN.unshift(aM);
    P = aN;
    return;
  };
  var E = function (aO) {
    var aM;
    if (aO > 0) {
      var aN = new Date();
      cookieTTL = aO * 24 * 60 * 60 * 1000;
      aM = new Date(aN.getTime() + cookieTTL);
    } else {
      aM = new Date(0);
    }
    document.cookie = Q + "=1;path=/;expires=" + aM.toUTCString();
  };
  var i = function (aO) {
    var aQ, aN;
    var aR;
    aR = document.cookie.split(";");
    for (aQ = 0; aQ < aR.length; aQ++) {
      var aP = aR[aQ];
      var aM = aP.indexOf("=");
      var aS = aP.substring(0, aM);
      if (aO == aS.replace(/^\s+|\s+$/g, "")) {
        return aP.substring(aM + 1);
      }
    }
    return null;
  };
  var b = function () {
    var aM = [];
    var aN;
    var aO = i("_saml_idp");
    if (aO != null) {
      aO = aO.replace(/^\s+|\s+$/g, "");
      aO = aO.replace("+", "%20");
      aO = aO.split("%20");
      for (aN = aO.length; aN > 0; aN--) {
        if (0 === aO[aN - 1].length) {
          continue;
        }
        var aP = av(decodeURIComponent(aO[aN - 1]));
        if (aP.length > 0) {
          aM.push(aP);
        }
      }
    }
    return aM;
  };
  var at = function (aR) {
    var aO = [];
    var aQ = aR.length;
    if (af) {
      return;
    }
    if (aQ > 5) {
      aQ = 5;
    }
    for (var aP = aQ; aP > 0; aP--) {
      if (aR[aP - 1].length > 0) {
        aO.push(encodeURIComponent(w(aR[aP - 1])));
      }
    }
    var aM = null;
    if (samlIdPCookieTTL) {
      var aN = new Date();
      cookieTTL = samlIdPCookieTTL * 24 * 60 * 60 * 1000;
      aM = new Date(aN.getTime() + cookieTTL);
    }
    document.cookie =
      "_saml_idp=" +
      aO.join("%20") +
      "; path = /" +
      (aM === null ? "" : "; expires=" + aM.toUTCString());
  };
  var w = function (aV) {
    var aM = "",
      aQ,
      aO,
      aN,
      aU,
      aT,
      aS,
      aR;
    for (var aP = 0; aP < aV.length; ) {
      aQ = aV.charCodeAt(aP++);
      aO = aV.charCodeAt(aP++);
      aN = aV.charCodeAt(aP++);
      aU = aQ >> 2;
      aT = ((aQ & 3) << 4) + (aO >> 4);
      aS = ((aO & 15) << 2) + (aN >> 6);
      aR = aN & 63;
      if (isNaN(aO)) {
        aS = aR = 64;
      } else {
        if (isNaN(aN)) {
          aR = 64;
        }
      }
      aM += W.charAt(aU) + W.charAt(aT) + W.charAt(aS) + W.charAt(aR);
    }
    return aM;
  };
  var av = function (aP) {
    var aN = "",
      aW,
      aU,
      aS,
      aV,
      aT,
      aR,
      aQ;
    var aO = 0;
    var aM = /[^A-Za-z0-9\+\/\=]/g;
    aP = aP.replace(/[^A-Za-z0-9\+\/\=]/g, "");
    do {
      aV = W.indexOf(aP.charAt(aO++));
      aT = W.indexOf(aP.charAt(aO++));
      aR = W.indexOf(aP.charAt(aO++));
      aQ = W.indexOf(aP.charAt(aO++));
      aW = (aV << 2) | (aT >> 4);
      aU = ((aT & 15) << 4) | (aR >> 2);
      aS = ((aR & 3) << 6) | aQ;
      aN = aN + String.fromCharCode(aW);
      if (aR != 64) {
        aN = aN + String.fromCharCode(aU);
      }
      if (aQ != 64) {
        aN = aN + String.fromCharCode(aS);
      }
      aW = aU = aS = "";
      aV = aT = aR = aQ = "";
    } while (aO < aP.length);
    return aN;
  };
  var ae = function (aN) {
    if (rootDivEl) {
      var aM = document.createTextNode(aN);
      rootDivEl.appendChild(aM);
    } else {
      alert("FATAL (NoDiv):" + aN);
    }
  };
  var noDisco = function (aM) {
    alert("FATAL - DISCO UI:" + aM);
    if (rootDivEl) {
      ae(aM);
    }
  };
  var s = function () {};
}
var idpsUIparams = new IdPSelectUIParms();
var idpSelectUI = new IdPSelectUI();

export { idpSelectUI, idpsUIparams };
