// // Javascript
// import 'jquery'
// import 'bootstrap'
// // import 'bootstrap/js/modal'
// import '@popperjs/core'
// import { idpSelectUI } from "../../assets/javascripts/idpselect";

// document.addEventListener("DOMContentLoaded", () => {
//   console.log("Drawing");
//   idpSelectUI.draw(idpsUIparams);
// });

// // CSS
// import 'bootstrap/dist/css/bootstrap.css'
// import "~/styles/cms";

// console.log(`typeof $ = ${typeof $}`);

// window.$ = $;
// window.jQuery = jQuery;
import "~/cms";
